import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  TextField,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddIcon from "@mui/icons-material/Add";
import { db } from "../../shared/firebase";
import { colors } from "../lib/theme";
import { useAuth } from "../../shared/useAuth";
import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  doc as firestoreDoc,
  deleteDoc,
  doc,
  addDoc,
  serverTimestamp,
  updateDoc,
  arrayUnion,
} from "firebase/firestore";
import RefreshLink from "../../shared/RefreshLink";
import ConfirmDialog from "../../shared/ConfirmDialog";
import { Helmet } from "react-helmet";
import AlbumArt from "../../shared/AlbumArt";
import ContainerBox from "../../shared/ContainerBox";
import { sendEmailVerification } from "firebase/auth";

const Dashboard = () => {
  const { user, loading } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [albums, setAlbums] = useState([]);
  const [albumsLoading, setAlbumsLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedAlbum, setSelectedAlbum] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [newAlbumTitle, setNewAlbumTitle] = useState("");
  const [openAddAlbumDialog, setOpenAddAlbumDialog] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [showNamePrompt, setShowNamePrompt] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);

  useEffect(() => {
    if (user && !user.emailVerified) {
      navigate("/verify-email");
    } else if (user) {
      setEmailVerified(true);
    }
  }, [user, navigate]);

  useEffect(() => {
    const fetchAlbums = async () => {
      if (user) {
        setAlbumsLoading(true);
        const albumsRef = collection(db, "albums");
        const q = query(albumsRef, where("userId", "==", user.uid));
        const sharedQ = query(
          albumsRef,
          where("sharedWith", "array-contains", user.uid)
        );

        const [ownedSnapshot, sharedSnapshot] = await Promise.all([
          getDocs(q),
          getDocs(sharedQ),
        ]);

        const fetchAlbumWithCreator = async (albumDoc) => {
          const albumData = { id: albumDoc.id, ...albumDoc.data() };
          const creatorDoc = await getDoc(
            firestoreDoc(db, "users", albumData.userId)
          );
          const creatorData = creatorDoc.data();
          return {
            ...albumData,
            creatorName: creatorData
              ? `${creatorData.firstName} ${creatorData.lastName}`
              : "Unknown",
            updatedAt: albumData.updatedAt || albumData.createdAt, // Use updatedAt if available, otherwise fall back to createdAt
          };
        };

        const ownedAlbums = await Promise.all(
          ownedSnapshot.docs.map(fetchAlbumWithCreator)
        );
        const sharedAlbums = await Promise.all(
          sharedSnapshot.docs.map(fetchAlbumWithCreator)
        );

        const allAlbums = [...ownedAlbums, ...sharedAlbums];

        // Sort albums by updatedAt in descending order (most recent first)
        const sortedAlbums = allAlbums.sort(
          (a, b) => b.updatedAt - a.updatedAt
        );

        setAlbums(sortedAlbums);
        setAlbumsLoading(false);
      }
    };

    fetchAlbums();
  }, [user]);

  useEffect(() => {
    const fetchUserData = async () => {
      if (user) {
        const userDoc = await getDoc(doc(db, "users", user.uid));
        const userData = userDoc.data();
        if (!userData?.firstName || !userData?.lastName) {
          setShowNamePrompt(true);
        }
      }
    };

    fetchUserData();
  }, [user]);

  useEffect(() => {
    const handleInvitedTo = async () => {
      const invitedToAlbum = localStorage.getItem('invitedTo');
      console.log("Album from localStorage:", invitedToAlbum);
      console.log("Current user:", user);

      if (invitedToAlbum && user) {
        try {
          console.log("Attempting to fetch album:", invitedToAlbum);
          const albumRef = doc(db, "albums", invitedToAlbum);
          const albumDoc = await getDoc(albumRef);

          if (albumDoc.exists()) {
            console.log("Album found:", albumDoc.id);
            const albumData = albumDoc.data();
            console.log("Album data:", albumData);

            if (
              albumData.invitedTo &&
              albumData.invitedTo.some(
                (email) => email.toLowerCase() === user.email.toLowerCase()
              )
            ) {
              console.log("User invited to album, updating sharedWith");
              await updateDoc(albumRef, {
                sharedWith: arrayUnion(user.uid),
              });
              console.log("sharedWith updated successfully");
              localStorage.removeItem('invitedTo');
            } else {
              console.log("User not invited to this album");
              localStorage.removeItem('invitedTo');
            }

            console.log("Redirecting to album page");
            window.location.href = `/album/${invitedToAlbum}`;
          } else {
            console.error("Album not found");
          }
        } catch (error) {
          console.error("Error handling next album:", error);
        }
      } else {
        console.log("No next album or user not logged in");
      }
    };

    handleInvitedTo();
  }, [location, navigate, user]);

  const handleMenuOpen = (event, album) => {
    setAnchorEl(event.currentTarget);
    setSelectedAlbum(album);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedAlbum(null);
  };

  const handleDeleteAlbum = () => {
    if (selectedAlbum) {
      setOpenDeleteDialog(true);
    }
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    handleMenuClose();
  };

  const handleConfirmDelete = async () => {
    try {
      await deleteDoc(doc(db, "albums", selectedAlbum.id));
      setAlbums(albums.filter((album) => album.id !== selectedAlbum.id));
      handleCloseDeleteDialog();
    } catch (error) {
      console.error("Error deleting album: ", error);
    }
  };

  const handleOpenNewAlbumDialog = () => {
    setOpenAddAlbumDialog(true);
  };

  const handleCloseNewAlbumDialog = () => {
    setOpenAddAlbumDialog(false);
    setNewAlbumTitle("");
  };

  const handleCreateNewAlbum = async () => {
    if (newAlbumTitle.trim()) {
      try {
        const newAlbumRef = await addDoc(collection(db, "albums"), {
          title: newAlbumTitle.trim(),
          userId: user.uid,
          createdAt: new Date(),
        });
        handleCloseNewAlbumDialog();
        window.location.href = `/album/${newAlbumRef.id}`;
      } catch (error) {
        console.error("Error creating new album: ", error);
      }
    }
  };

  const handleConfirmAddAlbum = async () => {
    if (newAlbumTitle.trim()) {
      try {
        const newAlbumRef = await addDoc(collection(db, "albums"), {
          title: newAlbumTitle.trim(),
          userId: user.uid,
          createdAt: new Date(),
          privacyLevel: 0,
        });
        setOpenAddAlbumDialog(false);
        setNewAlbumTitle("");
        window.location.href = `/album/${newAlbumRef.id}`;
      } catch (error) {
        console.error("Error creating new album: ", error);
      }
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleConfirmAddAlbum();
    }
  };

  const handleSaveName = async () => {
    if (firstName.trim() && lastName.trim()) {
      try {
        await updateDoc(doc(db, "users", user.uid), {
          firstName: firstName.trim(),
          lastName: lastName.trim(),
        });
        setShowNamePrompt(false);
      } catch (error) {
        console.error("Error updating user profile:", error);
      }
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "200px",
        }}
      >
        <CircularProgress sx={{ color: colors.gold }} />
      </Box>
    );
  }

  if (!emailVerified) {
    return null; // or you can return a loading indicator
  }

  return (
    <ContainerBox>
      <Helmet>
        <title>Dashboard - Golden Record</title>
        <meta
          name="description"
          content="View and manage your Golden Record albums"
        />
      </Helmet>
      {user && (
        <>
          <Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                justifyContent: "space-between",
                alignItems: { xs: "stretch", sm: "center" },
                mb: 4,
                gap: 2,
              }}
            >
              <Typography variant="h4" sx={{ fontSize: { xs: "1.5rem", sm: "2rem" } }}>
                Your Albums
              </Typography>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={handleOpenNewAlbumDialog}
                sx={{
                  width: { xs: "100%", sm: "auto" },
                  maxWidth: { xs: "100%", sm: "200px" }
                }}
              >
                Add Album
              </Button>
            </Box>
            {showNamePrompt && (
              <Box
                sx={{
                  mb: 4,
                  p: 3,
                  borderRadius: 2,
                  backgroundColor: colors.white,
                  border: `1px solid ${colors.gold}`,
                  maxWidth: '600px',
                  marginLeft: 0,
                }}
              >
                <Typography variant="h6" sx={{ mb: 2 }}>
                  Please provide your name
                </Typography>
                <Box sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', sm: 'row' },
                  alignItems: { xs: 'stretch', sm: 'center' },
                  gap: 2
                }}>
                  <TextField
                    label="First Name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    size="small"
                    fullWidth
                  />
                  <TextField
                    label="Last Name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    size="small"
                    fullWidth
                  />
                  <Button
                    variant="contained"
                    onClick={handleSaveName}
                    size="small"
                    sx={{ py: 1, px: 2, minWidth: { xs: '100%', sm: 'auto' } }}
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            )}
            {albumsLoading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "200px",
                }}
              >
                <CircularProgress sx={{ color: colors.navy }} />
              </Box>
            ) : albums.length > 0 ? (
              albums.map((album) => (
                <Box
                  key={album.id}
                  sx={{
                    mb: 2,
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row" },
                    alignItems: { xs: "center", sm: "flex-start" },
                    justifyContent: "space-between",
                    padding: { xs: 2, sm: 2 },
                    borderRadius: 2,
                    transition: "background-color 0.3s",
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.04)",
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "column", sm: "row" },
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <RefreshLink to={`/album/${album.id}`}>
                      <AlbumArt
                        imageUrl={album.imageUrl}
                        title={album.title}
                        size={200}
                      />
                    </RefreshLink>
                    <Box
                      sx={{
                        textAlign: { xs: "center", sm: "left" },
                        width: { xs: "100%", sm: "auto" },
                        ml: { xs: 0, sm: 2 },
                      }}
                    >
                      <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                        <RefreshLink to={`/album/${album.id}`}>
                          {album.title}
                        </RefreshLink>
                      </Typography>
                      <Typography variant="body2">
                        Created by:{" "}
                        <RefreshLink
                          sx={{ fontWeight: "bold" }}
                          to={`/user/${album.userId}`}
                        >
                          {album.creatorName}
                        </RefreshLink>
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ color: colors.lightGray, mt: 1 }}
                      >
                        Updated{" "}
                        {new Date(album.updatedAt.toDate()).toLocaleString()}
                      </Typography>
                    </Box>
                  </Box>
                  <IconButton
                    onClick={(event) => handleMenuOpen(event, album)}
                    sx={{
                      color: colors.navy,
                      alignSelf: { xs: "flex-end", sm: "center" },
                      mt: { xs: 1, sm: 0 },
                    }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                </Box>
              ))
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "200px",
                  textAlign: "center",
                }}
              >
                <Typography variant="h6" sx={{ mb: 2 }}>
                  You don't have any albums yet.
                </Typography>
                <Typography variant="body1">
                  Click the "Add Album" button to create your first album!
                </Typography>
              </Box>
            )}
          </Box>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={handleDeleteAlbum}>Delete</MenuItem>
          </Menu>
          <ConfirmDialog
            open={openDeleteDialog}
            onClose={handleCloseDeleteDialog}
            onConfirm={handleConfirmDelete}
            title="Delete Album"
            content={`Are you sure you want to delete the album "${selectedAlbum?.title}"? This action cannot be undone.`}
          />
          <ConfirmDialog
            open={openAddAlbumDialog}
            title="Create New Album"
            content={
              <TextField
                autoFocus
                margin="dense"
                id="albumTitle"
                label="Album Title"
                type="text"
                fullWidth
                variant="outlined"
                value={newAlbumTitle}
                onChange={(e) => setNewAlbumTitle(e.target.value)}
                onKeyPress={handleKeyPress}
              />
            }
            onClose={() => {
              setOpenAddAlbumDialog(false);
              setNewAlbumTitle("");
            }}
            onConfirm={handleConfirmAddAlbum}
            confirmText="Create"
            cancelText="Cancel"
            confirmButtonProps={{ color: "primary" }}
            cancelButtonProps={{ color: "primary" }}
          />
        </>
      )}
    </ContainerBox>
  );
};

export default Dashboard;
